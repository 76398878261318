<template>
  <v-container>
    <div style="height: 30px"></div>

    <v-row>
      <v-col class="text-center">
        <h1 :class="[isMobile ? '' : 'ccheadline', 'mb-2']">Privacy Policy</h1>
        <p :class="['mb-2', isMobile ? '' : 'f125']">
          Learn how we handle your data
        </p>
        <small>Updated 1 January, 2024</small>
      </v-col>
    </v-row>

    <v-row class="mt-10 mb-10">
      <v-col><v-divider></v-divider></v-col>
    </v-row>

    <v-row>
      <v-col v-if="!isMobile" cols="3">
        <v-list class="pclist ml-0" style="position: sticky; top: 0">
          <v-list-item
            v-for="(item, index) in pitems"
            :key="index"
            class="link"
            @click="goto(index)"
          >
            <v-list-item-content>
              <p class="mb-0">
                <b>{{ item }}</b>
              </p>
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon
                :color="isDT ? 'white' : 'black'"
                v-if="index == activeIndex"
                >mdi-chevron-right</v-icon
              >
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col :class="[isMobile ? '' : 'pt-8 pl-10']">
        <div id="introduction">
          <h3 class="mb-5 link">{{ pitems[0] }}</h3>
          <p>
            Growcify (or “us” or “we”) values your privacy and uses your
            personal information with the strictest degree of confidentiality.
            We at Growcify, collect and use information with regards to:
          </p>
          <p>- Customers</p>
          <p>- Merchants</p>
          <p>- Visitors</p>
          <p>
            The purpose of this Privacy Policy is to assist you in understanding
            how we use, share and collect your personal information. This
            Privacy Policy may be revised if we have updations in our privacy
            practices. We may inform you if any significant changes are carried
            out in the Privacy Policy. If you object to your information being
            transferred or used in this way, please do not provide the details
            of your information on the website.
          </p>
        </div>

        <div class="mt-12"></div>
        <div id="data">
          <h3 class="mb-5">{{ pitems[1] }}</h3>
          <p>
            a. Growcify may collect and use the following kinds of personal
            information:
          </p>
          <p>
            - Information that you provide for registering with Growcify on the
            website – https://growcify.com
          </p>
          <p>- Information regarding the usage of this website</p>
          <p>
            - Information (including but not limited to Name, Date of Birth/Age,
            Gender, Business/Profession, Family Details, Investment / Portfolio
            details, PAN No., KYC, Photograph, Aadhar Number, Relationship with
            Bankers / Brokers / Intermediaries, Bank Account/Demat Account /
            Trading Account etc.) that you provide for the purpose of
            subscribing to the services provided on the website
          </p>
          <p>- Any other information that you provide to Growcify</p>

          <p class="mt-8">
            b. Growcify processes your information for the following reasons:
          </p>
          <p>
            We process your information to fulfill contractual obligations or
            where we require your personal information for legitimate business
            purposes. Your personal information is processed after carefully
            evaluating the potential risks associated with your privacy. The
            reasons for processing your personal information include but not
            limited to:
          </p>
          <p>- preventing and mitigating risk</p>
          <p>- preventing fraudulent activity</p>
          <p>- providing and updating our products and services</p>
          <p>- providing information regarding usage of our website</p>
          <p>- providing periodical reports and analytics</p>
          <p>- testing additional features and services</p>
          <p>- assisting with advertising, marketing and other activities</p>

          <p class="mt-8">c. Growcify values your privacy</p>
          <p>
            - We ensure that we collect personal information only to the extent
            necessary to provide our services in the best possible manner while
            also adhering to the requirements under the various rules /
            regulations of applicable Indian Laws, from time to time.
          </p>
          <p>
            - We use your information to provide you various services and we aim
            to use the information to your benefit. Wherever required, we
            anonymize or delete the information when we no longer need it. We
            may combine the information provided by you on the related channels
            to ensure that you get optimal and high quality services.
          </p>
          <p>
            - We may be required to share the information given by you with our
            affiliates, associates, and/or third parties, where we feel there is
            scope for value addition and improving the quality of services
            imparted by us to you. In the event we disclose your personal
            information to our associates, agents or sub-contractors for these
            purposes, the associate, agent or sub-contractor in question will be
            under an obligation to use the said personal information in
            accordance with the terms of this Privacy Policy.
          </p>
          <p>
            - In addition to the aforesaid disclosures, we may also need to
            share your information, without obtaining your prior written
            consent, with government agencies or other regulatory bodies
            mandated under the law to obtain information for the purpose of
            verification of identity, or for prevention, detection,
            investigation including cyber incidents, prosecution, and punishment
            of offences, or where disclosure is necessary for compliance of a
            legal obligation.
          </p>

          <p class="mt-8">d. Growcify’s usage of cookies</p>
          <p>
            With the purpose of enhancing your digital experience, we use
            cookies that store and record your preferences and give us
            analytical data about your browsing behavior, such as the amount of
            time spent on our website, pages you visited on this website, etc.
            This information assists us in understanding customer interests and
            preferences and helps us improve our website. You acknowledge and
            expressly authorise us to use cookies by visiting our website. Most
            web browsers automatically accept cookies; however you can modify
            your browser setting to decline cookies.
          </p>
        </div>

        <div class="mt-12"></div>

        <div id="security">
          <h3 class="mb-5">{{ pitems[2] }}</h3>
          <p>
            We value your privacy and use commercially reasonable and
            technically adept safeguards to preserve the integrity of the
            personal information you provide us. Once we receive your personal
            information, we make commercially reasonable efforts to ensure the
            security of our systems. However, please note that this is not a
            guarantee that such information may not be accessed, disclosed,
            altered, or destroyed by breach of any of our physical, technical,
            or managerial safeguards.
          </p>
          <p>
            In the interest of privacy and security, we make reasonable
            endeavors to verify your identity before granting you access to our
            services. However, the responsibility to protect your account
            information and password etc. lies upon you.
          </p>
        </div>

        <div class="mt-12"></div>
        <div id="disclaimers">
          <h3 class="mb-5">{{ pitems[3] }}</h3>
          <p>
            a. While we ensure that appropriate steps are taken to maintain the
            security of personal information we collect from you, the open
            nature of the internet may leave scope for data to flow over
            networks with inadequate security measures and may be accessed and
            used by people other than those for whom the data is intended. We
            assume no responsibility of whatsoever nature as to make good the
            losses and damages you may incur, due to privacy and/or security
            breach of your personal information.
          </p>
          <p>
            b. While browsing our website you may find links to other websites
            on the internet. We assume no responsibility for the privacy
            policies (or lack thereof) of any third party as the same are not
            under our control. In case of the above, you should check the
            applicable privacy policy of the third party website to determine
            how they will handle any information they collect from you.
          </p>
          <p>
            c. We reserve the right to make modifications and revisions to the
            content of this website.
          </p>
          <p>
            d. We do not warranty the accuracy, timeliness, or quality of the
            electronic content. The content of the website cannot be copied,
            reproduced, republished, uploaded, posted, transmitted or
            distributed for any non-personal use without obtaining our prior
            permission. We reserve the right to terminate the accounts of
            subscribers/customers, who violate the proprietary rights, in
            addition to necessary legal and statutory action.
          </p>
          <p>
            e. Growcify’s website is provided “as is” without any
            representations or warranties, express or implied. We and our
            director, employees, affiliates and associates, make no
            representations or warranties in relation to the website or the data
            and information provided on the website.
          </p>
          <p>
            f. We and/or our affiliates are not liable for damages caused by any
            performance, failure of performance, error, omission, interruption,
            deletion, defect or any other related issue with regards to the
            functioning of the webiste. We shall not be responsible for any
            technical failure or malfunctioning of the software or delays of any
            kind.
          </p>
        </div>

        <div class="mt-12"></div>
        <div id="liability">
          <h3 class="mb-5">{{ pitems[4] }}</h3>
          <p>
            We and/or our directors, employees, affiliates and associates will
            not be liable to you (whether under the law of contract, the law of
            torts or otherwise under any other law) in relation to the contents
            of, or use of, or otherwise in connection with, this website. We
            and/or our directors, employees, affiliates and associates, will not
            be responsible for any indirect, special or consequential loss; or
            loss of revenue, income, profits, loss of contracts or business
            relationships, loss of reputation or goodwill, or loss or corruption
            of information or data. You agree that you will not bring any claim
            personally against us and/or our directors, employees, affiliates
            and associates in respect of any losses you suffer in connection
            with the website.
          </p>
        </div>

        <div class="mt-12"></div>
        <div id="deletion">
          <h3 class="mb-5">{{ pitems[5] }}</h3>
          <p>
            You can temporarily disable your account by going to the settings
            and deactivating your account. In case you want to delete your
            account, you can do it on your own from Settings within the app or
            you can write us to team@growcify.com to get your account deleted.
          </p>
        </div>

        <div class="mt-12"></div>
        <div id="jurisdiction">
          <h3 class="mb-5">{{ pitems[6] }}</h3>
          <p>
            Your use of this website and any dispute arising out of such use of
            the website is subject to the laws of India. You hereby consent to
            the jurisdiction and venue of courts in Jaipur, Rajasthan, India or
            as decided by Growcify in all disputes arising out of or relating to
            the use of this Website. Use of this Website is unauthorized in any
            jurisdiction that does not give effect to all provisions of these
            privacy policy, including without limitation this paragraph.
          </p>
        </div>

        <div class="mt-12"></div>
        <div id="contact">
          <h3 class="mb-5">{{ pitems[7] }}</h3>
          <p>
            If you have any questions or suggestions about my Privacy Policy, do
            not hesitate to contact us at [team at growcify dot com].
          </p>
        </div>
      </v-col>
    </v-row>

    <div style="height: 25px"></div>
  </v-container>
</template>
<script>
import $ from "jquery";

export default {
  name: "PrivacyPolicy",

  created() {
    document.title = "Growcify - Privacy Policy";
    $("html, body").animate({ scrollTop: 0 }, "slow");
  },

  methods: {
    goto(index) {
      let elementID = "introduction";
      if (index == 1) elementID = "data";
      else if (index == 2) elementID = "security";
      else if (index == 3) elementID = "disclaimers";
      else if (index == 4) elementID = "liability";
      else if (index == 5) elementID = "deletion";
      else if (index == 6) elementID = "jurisdiction";
      else if (index == 7) elementID = "contact";

      $("html, body").animate(
        { scrollTop: $("#" + elementID).offset().top },
        "slow"
      );
      this.activeIndex = index;
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  data() {
    return {
      activeIndex: 0,
      pitems: [
        "Introduction",
        "Collecting, using, and processing your personal information",
        "Our security practices",
        "Disclaimers",
        "Limitation of liability",
        "Deletion or removal of data or account",
        "Jurisdiction",
        "Contact us",
      ],
    };
  },
};
</script>
